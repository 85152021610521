<template>
  <div class="dsbook">
    <!-- <myHeader></myHeader> -->

    <div class="content">
      <div class="bookImg">
        <img :src="bookList.bookPhoto" alt="" />
      </div>
      <div class="book">
        <h4>{{ bookList.bookTitle }}</h4>
        <div class="by">
          <p style="margin-top:200px"><i>书 号:</i>{{ bookList.bookNumber }}</p>
          <p><i>出版社:</i>{{ bookList.bookCbs }}</p>
          <p>
            <i>定 价:</i><em> {{ bookList.bookMoney }}元 </em>
          </p>
        </div>
      </div>
    </div>
    <div class="matter">
      <h4><i class="line"></i> 内容简介</h4>
      <p v-html="bookList.bookName" style="text-indent: 2em;"></p>
    </div>
    <!-- <myFooter class="ft"></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { getBookSS } from '@/api/myHeader.js'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  created () {
    this.getList()
    // alert(this.$router.query.id)
  },
  data () {
    return {
      bookList: {}
    }
  },
  methods: {
    getList () {
      //alert(this.$route.query.id)
      let id = this.$route.query.id
      getBookSS(id).then(response => {
        this.bookList = response.data.data
      })
    }
  }
}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .dsbook {
    width: 100%;
    margin-top: 20px;
    background-color: #f3f3f3;
    .content {
      display: flex;
      flex-wrap: wrap;
      width: 1200px;
      height: 100%;
      margin: auto;
      background-color: #fff;
      border-top: 1px solid transparent;
      .bookImg {
        display: inline-block;
        width: 320px;
        height: 400px;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .book {
        flex: 1;
        padding: 40px 20px 40px 200px;
        h4 {
          font-family: MicrosoftYaHei-Bold;
          font-size: 28px;
          font-weight: 700;
          font-stretch: normal;
          line-height: 26px;
          letter-spacing: 1px;
          color: #333333;
          margin-bottom: 60px;
        }
        p {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #999999;
          margin: 25px 0;
          i {
            margin-right: 40px;
          }
          em {
            font-family: SourceHanSansCN-Medium;
            font-size: 32px;
            font-weight: normal;
            font-stretch: normal;
            // letter-spacing: -1px;
            color: #cc1914;
            b {
              font-size: 20px;
              font-weight: normal;
            }
          }
        }
      }
    }
    .matter {
      position: relative;
      width: 1200px;
      margin: 0 auto 20px;
      background-color: #fff;
      h4 {
        font-size: 20px;
        font-weight: 700;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 1px;
        color: #cc1914;
        margin-left: 20px;
        padding: 20px;
        .line {
          position: absolute;
          top: 25px;
          left: 20px;
          width: 5px;
          height: 24px;
          background-color: #cc1914;
        }
      }
      p {
        font-family: SourceHanSansCN-Normal;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 1px;
        color: #666666;
        padding: 0 20px;
        overflow: hidden;
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .dsbook {
    width: 100%;
    margin-top: 20px;
    background-color: #f3f3f3;
    .content {
      // display: flex;
      // flex-wrap: wrap;
      width: 100%;
      height: 100%;
      margin: auto;
      background-color: #fff;
      border-top: 1px solid transparent;
      .bookImg {
        display: inline-block;
        width: 100%;
        height: 400px;
        text-align: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .book {
        // text-align: center;
        h4 {
          font-family: MicrosoftYaHei-Bold;
          font-size: 18px;
          // font-weight: 700;
          font-stretch: normal;
          line-height: 26px;
          letter-spacing: 1px;
          color: #333333;
          margin-bottom: 30px;
          margin-top: 10px;
          margin-left: 20px;
        }
        .by {
          margin: 0 auto;
          text-align: left;
          margin-left: 20px;
          p {
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #999999;
            margin: 25px 0;
            &:nth-child(1) {
              margin-top: 0 !important;
            }
            &:nth-child(3) {
              margin-bottom: 0 !important;
            }
            i {
              margin-right: 40px;
            }
            em {
              font-family: SourceHanSansCN-Medium;
              font-size: 32px;
              font-weight: normal;
              font-stretch: normal;
              // letter-spacing: -1px;
              color: #cc1914;
              b {
                font-size: 20px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
    .matter {
      position: relative;
      width: 100%;
      // margin: 0 auto 20px;
      background-color: #fff;
      h4 {
        font-size: 20px;
        font-weight: 700;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 1px;
        color: #cc1914;
        margin-left: 20px;
        padding: 20px;
        .line {
          position: absolute;
          top: 25px;
          left: 20px;
          width: 5px;
          height: 24px;
          background-color: #cc1914;
        }
      }
      p {
        font-family: SourceHanSansCN-Normal;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 32px;
        letter-spacing: 1px;
        color: #666666;
        // padding: 0 20px;
        overflow: hidden;
        p {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
