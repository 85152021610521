<template>
  <div class="history">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <ul class="nav">
        <li>
          <img class="item" src="../../assets/img/分类.png" alt="" />
          <span class="t1">新华传承</span>
          <span class="t2">XINHUA HERITAGE</span>
        </li>
        <li
          :class="{ active: index == isActive }"
          v-for="(item, index) in list1"
          :key="index"
          @click="changeClass(item.id)"
        >
          <img class="icon" :src="item.imgUrl" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="telephone">
        <!-- <img src="../../assets/img/tel.png" alt="" />
        <p class="t3">24小时服务热线 :</p>
        <p class="t4">023-1234-5678</p> -->
        <img src="../../assets/img/ewm.jpg" alt="" />
      </div>

      <div class="book">
        <img src="../../assets/img/新华传承.jpg" alt="" class="imgBar" />
                <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
<div class="url1">
        <img class="icon" src="@/assets/img/白色箭头.png" alt="" />
        <h4>新华历史</h4></div>
        <div class="info" v-for="(item, index) in styList" :key="index">
          <p v-html="item.nameJs"></p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { listXinhuaOne } from '@/api/charactersIntro.js'
import {  getMenuList2 } from '@/api/myHeader.js'

export default {
  components: {
    // myHeader,
    // myFooter
  },
  data () {
    return {
      isActive: 0,
            menuList3: [],
      isActives: 0,
      imgUrl: require('@/assets/img/黑色箭头.png'),
      list1: [
        {
          imgUrl: require('@/assets/img/白色箭头.png'),
          name: '新华历史',
          com: 'two',
          id: 0
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '新华人物事迹',
          com: 'one',
          id: 1
        }
      ],
      styList: []
    }
  },
  created () {
        this.isActive = sessionStorage.getItem('activeNo')
    listXinhuaOne().then(res => {
      console.log('111', res.data.rows)
      this.styList = res.data.rows
      //window.console.log('1221', personList)
    })
        getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[7].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[6].comumns)
    })

  },
  methods: {
    changeClass (id) {
            // sessionStorage.setItem('activeNo', index)
      // console.log('进来了', id)
      // this.isActive = id
      this.list1.forEach(item => {
        item.imgUrl = require('@/assets/img/黑色箭头.png')
        //alert(id)
        if (id === 1) {
          //人物事迹
          this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
        } else if (id === 0) {
          //新华历史
          this.$router.push({ path: '/history', query: { menuId: id } })
        }
      })
      this.list1[id].imgUrl = require('@/assets/img/白色箭头.png')
      listXinhuaOne()
    },
            toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },

  }
}
</script>
<style lang="less">
// PC端
@media only screen and (min-width: 768px) {
  .history {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        width: 225px;
        height: 230px;
        color: #343434;
        background-color: #fff;
        margin-right: 35px;
        margin-top: 20px;
        li {
          position: relative;
          width: 100%;
          height: 70px;
          line-height: 70px;
          border-bottom: 1px solid #e6e6e6;
          padding-left: 20px;
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 15px;
            position: absolute;
            top: 25px;
            left: 20px;
            // background: url('~@/assets/img/黑色箭头.png') no-repeat;
            // background-size: 100%;
          }
          &:nth-child(n + 2):hover {
            color: #fff;
            background-color: #cc1914;
            .icon {
              background: url('~@/assets/img/白色箭头.png') no-repeat;
              background-size: 100%;
            }
          }
          span {
            font-size: 16px;
            font-weight: 700;
            margin-left: 45px;
          }
        }
        li:nth-child(1) {
          width: 100%;
          height: 90px;
          padding-top: 32px;
          text-align: left;
          line-height: 0;
          .item {
            width: 26px;
            height: 26px;
            position: absolute;
            top: 31px;
            left: 20px;
          }
          .t1 {
            margin-left: 42px;
            margin-right: 30px;
          }
          .t2 {
            display: inline-block;
            font-size: 14px;
            margin-left: 42px;
            margin-top: 20px;
          }
        }
      }
      .telephone {
        position: absolute;
        top: 260px;
        left: 0;
        width: 225px;
        height: 160px;
        text-align: center;
        color: #fff;
        background-color: #cc1914;
        img {
          display: block;
          width: 225px;
          height: 225px;
          // margin: 30px 0 10px 90px;
        }
        .t3 {
          font-size: 16px;
        }
        .t4 {
          font-size: 22px;
          margin-top: 10px;
        }
      }
      .book {
        flex: 1;
        // background-color: pink;
        margin-bottom: 40px;
        position: relative;
        background-color: #fff;
        // padding: 0 20px 20px;
        margin-top: 20px;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: none;
        }
        .icon {
          position: absolute;
          top: 370px;
          left: 0px;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 20px;
          margin-top: 5px;
        }
        .info {
          padding: 0 20px 20px;
          background-color: #fff;
          p {
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            letter-spacing: 2px;
            color: #666666;
            text-indent: 2em;
          }
        }
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .history {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        display: none;
      }
      .telephone {
        display: none;
      }
      .book {
        flex: 1;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
            background-color: #fff;
        // margin-top: 20px;

img {
          width: 100%;
        }
                .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
            overflow: hidden;
            margin: 0;
          }
        }
        .url1 {
          position: relative;

        .icon {
          position: absolute;
          top: 0px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        }
        .info {
          flex: 1;
          padding: 10px;
              background-color: #fff;
          p {
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 25px;
            // letter-spacing: 2px;
            color: #666666;
            // margin: 20px 0;
            text-indent: 2em;
          }
        }
      }
    }
        .isColors {
      color: #fff;
      background-color: #cc1914;
    }

  }
}
</style>
