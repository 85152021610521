<template>
  <div id="app">
    <el-container>
      <el-header style="height:80px; padding:0">
        <myHeader></myHeader>
      </el-header>
      <el-main style="padding:0; background-color:#f3f3f3">
        <router-view v-if="isReload" />
      </el-main>
      <el-footer style="padding: 0"><myFooter></myFooter></el-footer>
    </el-container>
  </div>
</template>
<script>
import myHeader from '../src/views/myHeader'
import myFooter from '../src/views/myFooter'
export default {
  components: {
    myHeader,
    myFooter
  },
  provide() {
    return {
      reload: this.reload
    }
},
data() {
  return {
      isReload: true
    }
},
 methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}

</script>
<style lang="less"></style>
