/**
 * 项目内部配置集合
 * @author Guiyun
 * @create 2017.7.20
 */
const config = {
  // 测试地址
  // baseURL: 'http://172.10.162.223:8001',
  baseURL: 'https://www.gsxhsd.com/prod-api',
  // baseURL: 'http://gsxhsd.com',
  // baseURL: '/prod-api',

  enableMock: false
}
export default config
