<template>
  <div class="rankingList">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <ul class="nav">
        <li>
          <img class="item" src="../../assets/img/分类.png" alt="" />
          <span class="t1">时尚阅读</span>
          <span class="t2">FASHION READING</span>
        </li>
        <li
          
          v-for="(item, index) in list1"
          :key="index"
          @click="changeClass(index, item.com)"
          :class="{ active: isActive == index  }"
        >
          <img class="icon" :src="item.imgUrl" alt="" />
          <span>{{ item.name }} </span>
        </li>
      </ul>
      <div class="telephone">
        <!-- <img src="../../assets/img/tel.png" alt="" />
        <p class="t3">24小时服务热线 :</p>
        <p class="t4">023-1234-5678</p> -->
        <img src="../../assets/img/ewm.jpg" alt="" />
      </div>

      <div class="book">
        <img src="../../assets/img/时尚阅读.jpg" alt="" class="imgBar" />
                <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
        <div class="url1">
        <img class="icon" src="@/assets/img/白色箭头.png" alt="" />
        <h4>排行榜</h4></div>
        <ul class="rankNav">
          <li
            v-for="(item, index) in list"
            :key="index"
            :class="{ activeColor: isActivePh === index }"
            @click="clickPh(index, item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div
          class="bookList clearfix"
          v-for="(item, index) in leaderboardList"
          :key="index"
          @click="bookInfo(item.id)"
        >
          <img :src="item.bookPhoto" alt="" class="img" />
          <p class="bookName">{{ item.book }}</p>
          <p>
            销量: <span class="num">{{ item.sales }}</span>
          </p>
          <div class="imgs">
            <span class="i">{{ index + 1 }}</span>
            <img :src="item.imgCon" alt="" />
          </div>
        </div>
        <!-- <div class="page">
          <el-pagination background layout="prev, pager, next" :total="10">
          </el-pagination>
        </div> -->
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { rankingList, listSort, listLeaderboard, getMenuList2} from '@/api/myHeader.js'

export default {
  components: {
    // myHeader,
    // myFooter
  },
  data () {
    return {
      isActive: 2,
      isActivePh: 0,
       menuList3: [],
       isActives: 2,
      color: '',
      imgUrl: require('@/assets/img/黑色箭头.png'),
      list1: [
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '党史学习教育书目推荐',
          idzt: 0,
          com: '/study'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '新书推荐',
          idzt: 1,
          com: '/recommend'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '排行榜',
          idzt: 2,
          com: '/rankingList'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '书籍查询',
          idzt: 3,
          com: '/bookSearch'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '书目下载',
          idzt: 4,
          com: '/bookDownload'
        }
      ],
      list: [
        // '人文社科',
        // '经管励志',
        // '文学',
        // '小说',
        // '艺术',
        // '医学',
        // '科普读物',
        // '少儿',
        // '教育辅助',
        // '考试',
        // '敦煌研究',
        // '政区地图'
      ],
      list2: [],
      list3: [],
      leaderboardList: []
    }
  },
  created () {
    listSort().then(response => {
      this.list = response.data.rows
      console.log('1111111', this.list)
    })
    this.getListlistLeaderboard()
    this.isActive = sessionStorage.getItem('activeNo') 
        getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[6].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[6].comumns)
    })

  },
  methods: {
    getListlistLeaderboard () {
      listLeaderboard(this.queryParams).then(response => {
        this.leaderboardList = response.data.rows
        this.leaderboardList.forEach((val, idx) => {
          if (idx == 0) {
            val.imgCon = require('@/assets/img/热销1.png')
          } else if (idx == 1) {
            val.imgCon = require('@/assets/img/热销2.png')
          } else if (idx == 2) {
            val.imgCon = require('@/assets/img/热销3.png')
          } else {
            val.imgCon = require('@/assets/img/热销.png')
          }
        })
      })
    },
    changeClass (index, com) {
      sessionStorage.setItem('activeNo', index)
      this.isActive = index
      this.$router.push(com)
      this.list1.forEach(item => {
        item.imgUrl = require('@/assets/img/黑色箭头.png')
        this.list1[index].imgUrl = require('@/assets/img/白色箭头.png')
      })
      this.$forceUpdate()
    },
            toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },

    clickPh (index, bookState) {
      this.isActivePh = index

      console.log({ bookState })
      listLeaderboard({ bookState }).then(response => {
        this.leaderboardList = response.data.rows
        this.leaderboardList.forEach((val, idx) => {
          if (idx == 0) {
            val.imgCon = require('@/assets/img/热销1.png')
          } else if (idx == 1) {
            val.imgCon = require('@/assets/img/热销2.png')
          } else if (idx == 2) {
            val.imgCon = require('@/assets/img/热销3.png')
          } else {
            val.imgCon = require('@/assets/img/热销.png')
          }
        })
      })

      this.$forceUpdate()
      console.log('排行榜1', this.leaderboardList)
    },
    getList () {
      rankingList(this.queryParams).then(res => {
        this.list3 = res.data.rows
        console.log('排行榜', res.data)
        this.bookListPage = this.list2.slice(
          this.pageVal - 1,
          this.everypageNum
        )
        this.pagination.total = res.data.rows.length
      })
    },
    bookInfo (id) {
                    let { href } = this.$router.resolve({
        path: '/rankingInfo', // 这里写的是要跳转的路由地址
        query: { id: id } // 这里写的是页面参数
      })
      window.open(href, '_blank')
    }
  }
}
</script>
<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
.rankingList {
  background-color: #f3f3f3;
  // .banner {
  //   margin-bottom: 90px;
  //   img {
  //     width: 100%;
  //   }
  // }
  .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .nav {
      width: 245px;
      height: 440px;
      color: #343434;
      background-color: #fff;
      margin-right: 35px;
      margin-top: 20px;
      li {
        position: relative;
        width: 100%;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 20px;
        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
          position: absolute;
          top: 25px;
          left: 20px;
          // background: url('~@/assets/img/黑色箭头.png') no-repeat;
          // background-size: 100%;
        }
        &:nth-child(n + 2):hover {
          color: #fff;
          background-color: #cc1914;
          .icon {
            background: url('~@/assets/img/白色箭头.png') no-repeat;
            background-size: 100%;
          }
        }
        span {
          font-size: 16px;
          font-weight: 700;
          margin-left: 45px;
        }
      }
      li:nth-child(1) {
        width: 100%;
        height: 90px;
        padding-top: 32px;
        text-align: left;
        line-height: 0;
        .item {
          width: 26px;
          height: 26px;
          position: absolute;
          top: 31px;
          left: 20px;
        }
        .t1 {
          margin-left: 42px;
          margin-right: 30px;
        }
        .t2 {
          display: inline-block;
          font-size: 14px;
          margin-left: 42px;
          margin-top: 20px;
        }
      }
    }
    .telephone {
      position: absolute;
      top: 470px;
      left: 0;
      width: 245px;
      height: 160px;
      text-align: center;
      color: #fff;
      background-color: #cc1914;

      img {
        display: block;
        width: 245px;
        height: 225px;
      }
      .t3 {
        font-size: 16px;
      }
      .t4 {
        font-size: 22px;
        margin-top: 10px;
      }
    }
    .book {
      flex: 1;
      margin-bottom: 40px;
      position: relative;
      // background-color: pink;
      background-color: #fff;
      margin-top: 20px;
      .imgBar {
        width: 100%;
      }
.menuCross {
  display: none;
}
      .icon {
        position: absolute;
        top: 364px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        color: #cc1914;
        font-size: 20px;
        margin-left: 30px;
        margin-bottom: 20px;
        margin-top: 5px;
      }
      .rankNav {
        display: flex;
        justify-content: space-between;
        margin: 0 20px 20px;
        li {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 2px;
          color: #333333;
          border-bottom: 2px solid #ddd;
          text-align: center;
          padding: 0 8px;
          cursor: pointer;
        }
      }
      .bookList {
        float: left;
        position: relative;
        width: 210px;
        height: 295px;
        margin-left: 20px;
        margin-bottom: 20px;
        // text-align: center;
        border: 1px solid #ccc;
        .img {
          display: inline-block;
          width: 166px;
          height: 232px;
          width: 80%;
        }
        .bookName {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p {
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 2px;
          color: #333333;
          margin: 5px 0 5px 20px;
          .num {
            color: #cc1914;
          }
        }
        .imgs {
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          height: 40px;
          .i {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 20px;
            color: #fff;
          }
        }
      }

      .page {
        width: 390px;
        height: 30px;
        margin: auto;
        // background-color: #f60;
        .el-pager {
          // width: 390px;
          height: 30px;
          li {
            width: 37px;
            height: 30px;
            margin: 0;
            border: 1px solid #e6e6e6;
          }
        }
      }
    }
  }
}
}
// 移动端
@media only screen and (max-width: 768px) {
.rankingList {
  background-color: #f3f3f3;
  // .banner {
  //   margin-bottom: 90px;
  //   img {
  //     width: 100%;
  //   }
  // }
  .content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    .nav,.telephone {
      display: none;
    }
    .book {
      // flex: 1;
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      // background-color: pink;
      background-color: #fff;
      // margin-top: 20px;
      .imgBar {
        width: 100%;
      }
        .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
            overflow: hidden;
            margin: 0;
          }
        }

        .url1 {
          position: relative;
          .icon {
            position: absolute;
            top: 0px;
            left: 0;
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 30px;
          }
          h4 {
            color: #d13632;
            font-size: 20px;
            margin-left: 30px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }
      .rankNav {
        display: flex;
        justify-content: space-between;
        margin: 0 0px 20px;
        li {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 2px;
          color: #333333;
          border-bottom: 2px solid #ddd;
          text-align: center;
          padding: 0 8px;
          cursor: pointer;
        }
      }
      .bookList {
        float: left;
        position: relative;
        width: 41.5%;
        // height: 295px;
        margin-left: 18px;
        margin-bottom: 18px;
        // text-align: center;
        border: 1px solid #ccc;
        &:nth-child(2n) {
                  width: 41.5%;
        // height: 295px;
        margin-left: 18px;
        margin-right: 18px;
        margin-bottom: 18px;
        // text-align: center;
        border: 1px solid #ccc;

        }
        .img {
          display: inline-block;
          // width: 166px;
          height: 200px;
          width: 80%;
        }
        .bookName {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        p {
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 2px;
          color: #333333;
          margin: 5px 0 5px 20px;
          .num {
            color: #cc1914;
          }
        }
        .imgs {
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          height: 40px;
          .i {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 20px;
            color: #fff;
          }
        }
      }

      .page {
        width: 390px;
        height: 30px;
        margin: auto;
        // background-color: #f60;
        .el-pager {
          // width: 390px;
          height: 30px;
          li {
            width: 37px;
            height: 30px;
            margin: 0;
            border: 1px solid #e6e6e6;
          }
        }
      }
    }
        .isColors {
      color: #fff;
      background-color: #cc1914;
    }
  }
}
}
.activeColor {
  color: #cc1914 !important;
  border-bottom: 2px solid #cc1914 !important;
}
.active {
    color: #fff;
    background-color: #cc1914;
}
</style>
