import _fetch from './request.js'
// 查询新华人物事迹列表
function listXinhua (query) {
  return _fetch({
    url: '/open/interface/listTbXinhua',
    method: 'get',
    params: query
  })
}
// 查询新华历史列表
function listXinhuaOne (query) {
  return _fetch({
    url: '/open/interface/listTbOldXinhua',
    method: 'get',
    params: query
  })
}
export { listXinhua, listXinhuaOne }
