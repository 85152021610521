<template>
  <div class="party">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <ul class="nav">
        <li>
          <img class="item" src="../../assets/img/分类.png" alt="" />
          <span class="t1">党建引领</span>
          <span class="t2">
            THE PARTY LEADS
          </span>
        </li>
      </ul>
      <div class="telephone">
        <img src="../../assets/img/ewm.jpg" alt="" />
      </div>
      <div class="book">
        <img
          src="../../assets/img/党建引领banner.jpg"
          alt=""
          style="width:100%"
        />
        <div class="url1">
        <img class="icon" src="@/assets/img/白色箭头.png" alt="" />
        <h4>党建引领</h4></div>
        <div class="news" v-for="(item, index) in listPage" :key="index">
          <img :src="item.photo3" alt="" />
          <div class="info">
            <h4>
              {{ item.menuName }}
            </h4>
            <p v-html="item.bookContent"></p>
            <a @click="toInfo(item)" style="cursor:pointer">了解更多>></a>
          </div>
        </div>
        <div class="page">
          <el-pagination
            :total="pagination.total"
            :page-size="everypageNum"
            :current-page="pagination.currentPage"
            @current-change="currentChange"
            layout="total,prev,pager,next,jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { party } from '@/api/myHeader.js'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  created () {
    this.party()
  },
  data () {
    return {
      list: [],
      listPage: [],
      pagination: {
        total: 0,
        pageSize: 5,
        currentPage: 1
      },
      everypageNum: 10, //每一页显示多少条
      pageVal: 1
    }
  },
  methods: {
    party () {
      let menuId = this.$route.query.menuId
      //alert(menuId)
      party(menuId).then(res => {
        this.list = res.data.data
        this.listPage = this.list.slice(this.pageVal - 1, this.everypageNum)
        this.pagination.total = this.list.length

        console.log('=====', res.data.data)
      })
    },

    toInfo (item) {
      console.log('11', item)
      if (item.isFrame == 1) {
        console.log('====', item.articleId)
                      let { href } = this.$router.resolve({
        path: '/Info', // 这里写的是要跳转的路由地址
        query: { id: item.articleId } // 这里写的是页面参数
      })
      window.open(href, '_blank')
      } else {
        this.$router.push(
          'https://fanyi.baidu.com/?aldtype=16047#en/zh/Avoided%20redundant%20navigation%20to%20current%20location%3A%20%22%2Fparty%22.'
        )
      }
      // if ((isFrame = 1)) {
      //   console.log(111)
      // }
      // this.$router.push('/Info') // if ((isFrame = 1)) {
      //   console.log(111)
      // }
      // this.$router.push('/Info')
    },
    // 页码改变
    currentChange (val) {
      this.pageVal = val //此处为用户点击第几页
      // 1: 0,19  当用户点击第一页，那么就是前20条，对耶下标位置应为0至19
      //后面截取的位置
      var that = this
      let start = that.everypageNum * val - 10
      let end = that.everypageNum * val
      if (that.list.length <= that.everypageNum) {
        //这种情况为只有20条或者20条以下数据的情况
        that.listPage = that.list
      } else {
        //这种情况为大于20条数据
        if (that.everypageNum * val >= that.list.length) {
          //用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
          that.listPage = that.list.slice(start)
        } else {
          //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
          that.listPage = that.list.slice(start, end)
        }
      }
    }
  }
}
</script>

<style lang="less">
// PC端
@media only screen and (min-width: 768px) {
  .party {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        width: 225px;
        height: 90px;
        color: #343434;
        background-color: #fff;
        margin-right: 35px;
        li {
          position: relative;
          width: 100%;
          height: 70px;
          line-height: 70px;
          border-bottom: 1px solid #e6e6e6;
          padding-left: 20px;
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 15px;
            position: absolute;
            top: 25px;
            left: 20px;
            background: url('~@/assets/img/黑色箭头.png') no-repeat;
            background-size: 100%;
          }
          &:nth-child(n + 2):hover {
            color: #fff;
            background-color: #cc1914;
            .icon {
              background: url('~@/assets/img/白色箭头.png') no-repeat;
              background-size: 100%;
            }
          }
          span {
            font-size: 16px;
            font-weight: 700;
            margin-left: 45px;
          }
        }
        li:nth-child(1) {
          width: 100%;
          height: 90px;
          padding-top: 32px;
          text-align: left;
          line-height: 0;
          .item {
            width: 26px;
            height: 26px;
            position: absolute;
            top: 31px;
            left: 20px;
          }
          .t1 {
            margin-left: 42px;
            margin-right: 30px;
          }
          .t2 {
            display: inline-block;
            font-size: 14px;
            margin-left: 42px;
            margin-top: 20px;
          }
        }
      }
      .telephone {
        position: absolute;
        top: 120px;
        left: 0;
        width: 225px;
        height: 160px;
        text-align: center;
        color: #fff;
        background-color: #cc1914;
        img {
          display: block;
          width: 225px;
          height: 225px;
          // margin: 30px 0 10px 90px;
        }
        .t3 {
          font-size: 16px;
        }
        .t4 {
          font-size: 22px;
          margin-top: 10px;
        }
      }
      .book {
        flex: 1;
        // background-color: pink;
        margin-bottom: 40px;
        position: relative;
        margin-top: 20px;
        /* background-color: #f60; */
        .icon {
          position: absolute;
          top: 372px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 20px;
          margin-top: 5px;
        }
        .news {
          display: flex;
          width: 100%;
          padding: 30px;
          background-color: #fff;
          border-bottom: 1px solid #ccc;
          img {
            display: inline-block;
            width: 200px;
            height: 165px;
          }
          .info {
            flex: 1;
            h4 {
              margin-top: 20px;
              font-size: 16px;
              color: #333;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            p {
              height: 48px;
              font-size: 14px;
              line-height: 24px;
              margin-left: 16px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            a {
              display: block;
              width: 125px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              margin-left: 30px;
              margin-top: 21px;
              font-size: 14px;
              color: #fff;
              background-color: #cc1914;
            }
          }
        }
        .page {
          width: 390px;
          height: 30px;
          margin: 20px auto 0;
          // background-color: #f60;
          .el-pager {
            // width: 390px;
            height: 30px;
            li {
              width: 37px;
              height: 30px;
              margin: 0;
              border: 1px solid #e6e6e6;
            }
          }
        }
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .party {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        display: none;
      }
      .telephone {
        display: none;
      }
      .book {
        // flex: 1;
        // background-color: pink;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        // margin-top: 20px;
        /* background-color: #f60; */
        .url1{
          position: relative;
        .icon {
          position: absolute;
          top: 0px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 10px;
          margin-top: 5px;
        }
        }
        .news {
          display: flex;
          width: 100%;
          padding: 10px;
          background-color: #fff;
          border-bottom: 1px solid #ccc;
          img {
            display: inline-block;
            width: 150px;
            height: 130px;
          }
          .info {
            flex: 1;
            background-color: #fff;
            h4 {
              // margin-top: 20px;
              font-size: 16px;
              color: #333;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              line-height: 17px;
               margin: 5px 0 11px 15px;
            }
            p {
              height: 48px;
              font-size: 14px;
              line-height: 24px;
              margin-left: 15px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              p {
                margin: 0;
              }
            }
            a {
              display: block;
              width: 80px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              margin-left: 15px;
              margin-top: 21px;
              font-size: 14px;
              color: #fff;
              background-color: #cc1914;
            }
          }
        }
        .page {
          width: 390px;
          height: 30px;
          margin: 20px auto 0;
          // background-color: #f60;
          .el-pager {
            // width: 390px;
            height: 30px;
            li {
              width: 37px;
              height: 30px;
              margin: 0;
              border: 1px solid #e6e6e6;
            }
          }
        }
      }
    }
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
