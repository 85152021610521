<template>
  <div class="filiale">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <!-- <div class="banner"></div> -->
    <div class="content">
      <navBar class="navBar"></navBar>
      <div class="book">
        <img src="../../assets/img/企业风貌.jpg" alt="" class="imgBar" />
        <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
        <div class="url1">
          <img class="icon" src="@/assets/img/红色箭头.png" alt="" />
          <h4>子公司简介</h4>
        </div>
        <div class="news" v-for="(item, index) in listPage" :key="index">
          <img :src="item.pare5" alt="" style="margin-top:30px" />
          <div class="info">
            <h4>
              {{ item.pare4 }}
            </h4>
            <p v-html="item.companyName"></p>
            <a @click="toInfo(item.dscityId)" style="cursor:pointer"
              >了解更多>></a
            >
          </div>
        </div>
        <div class="page page_pc">
          <el-pagination
            :total="pagination.total"
            :page-size="everypageNum"
            :current-page="pagination.currentPage"
            @current-change="currentChange"
            layout="total,prev,pager,next,jumper"
          >
          </el-pagination>
        </div>
      </div>
      <div class="page page_ydd">
        <el-pagination
          :total="pagination.total"
          :page-size="everypageNum"
          :current-page="pagination.currentPage"
          @current-change="currentChange"
          layout="total,prev,pager,next,jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import navBar from '../navBar'
import { listMenuOne, getMenuList2 } from '@/api/myHeader.js'

export default {
  components: {
    // myHeader,
    // myFooter,
    navBar
  },
  created () {
    this.party()
    getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[1].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[1].comumns)
    })
  },
  data () {
    return {
      // 分公司简介数据
      isActives: 1,
      list: [],
      listPage: [],
      menuList3: [],
      pagination: {
        total: 0,
        pageSize: 5,
        currentPage: 1
      },
      everypageNum: 5, //每一页显示多少条
      pageVal: 1
    }
  },
  methods: {
    party () {
      // console.log('alertJIALAI')
      listMenuOne().then(res => {
        this.list = res.data.rows
        console.log('222', res.data.rows)
        this.listPage = this.list.slice(this.pageVal - 1, this.everypageNum)
        this.pagination.total = res.data.rows.length
        console.log('1111', this.listPage)
      })
    },
    toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },
    toInfo (dscityId) {
      // console.log('11', dscityId)
      // console.log('====', dscityId)
      let { href } = this.$router.resolve({
        path: '/branchDetails', // 这里写的是要跳转的路由地址
        query: { id: dscityId } // 这里写的是页面参数
      })
      window.open(href, '_blank')
    },
    // 页码改变
    currentChange (val) {
      this.pageVal = val //此处为用户点击第几页
      // 1: 0,19  当用户点击第一页，那么就是前20条，对耶下标位置应为0至19
      //后面截取的位置
      var that = this
      let start = that.everypageNum * val - 5
      let end = that.everypageNum * val
      if (that.list.length <= that.everypageNum) {
        //这种情况为只有20条或者20条以下数据的情况
        that.listPage = that.list
      } else {
        //这种情况为大于20条数据
        if (that.everypageNum * val >= that.list.length) {
          //用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
          that.listPage = that.list.slice(start)
        } else {
          //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
          that.listPage = that.list.slice(start, end)
        }
      }
    }
  }
}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .filiale {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      .navBar {
        width: 260px;
      }
      .book {
        flex: 1;
        width: 940px;
        margin-bottom: 20px;
        position: relative;
        margin-top: 20px;
        background-color: #fff;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: none;
        }

        .icon {
          position: absolute;
          top: 385px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          // margin-bottom: 20px;
          margin: 20px 0 20px 25px;
        }
        .el-table tr {
          font-size: 16px;
          color: #343434;
          text-align: center;
        }
        .info {
          font-size: 16px;
          color: #343434;
          padding: 20px;
          background-color: #fff;

          img {
            display: block;
            width: 916px;
            height: 486px;
            // background-color: #b2b2b2;
          }
          p {
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            // letter-spacing: 2px;
            color: #666666;
            // margin: 20px 0;
            text-indent: 2em;
            .top {
              margin: 20px 0;
            }
          }
          .listMenuOne {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .comy {
            width: 292px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            font-size: 28px;
            background-color: #0087ff;
            opacity: 0.8;
            margin-right: 14px;
            margin-bottom: 20px;
          }
        }
      }
      .news {
        display: flex;
        width: 100%;
        padding: 0 30px 30px;
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        img {
          display: inline-block;
          width: 200px;
          height: 165px;
        }
        .info {
          flex: 1;
          h4 {
            margin-top: 20px;
            font-size: 16px;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            line-height: 17px;
          }
          p {
            height: 48px;
            font-size: 14px;
            line-height: 24px;
            margin-left: 17px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            span {
              color: #666 !important;
            }
          }
          a {
            display: block;
            width: 125px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-left: 30px;
            margin-top: 21px;
            font-size: 14px;
            color: #fff;
            background-color: #cc1914;
          }
        }
      }
      .page {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .page_ydd {
        display: none;
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .filiale {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      // display: flex;
      .navBar {
        display: none;
      }
      .book {
        // flex: 1;
        margin-bottom: 20px;
        position: relative;
        // margin-top: 20px;
        background-color: #fff;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
          }
        }
        .url1 {
          position: relative;

          .icon {
            position: absolute;
            top: 0px;
            left: 0;
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 30px;
          }
          h4 {
            color: #d13632;
            font-size: 20px;
            margin-left: 30px;
            // margin-bottom: 20px;
            margin: 20px 0 0px 25px;
          }
        }
        .el-table tr {
          font-size: 16px;
          color: #343434;
          text-align: center;
        }
        .info {
          font-size: 16px;
          color: #343434;
          padding: 0 0 0 15px !important;
          background-color: #fff;

          img {
            display: block;
            width: 916px;
            height: 486px;
            // background-color: #b2b2b2;
          }
          p {
            // width: 918px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            // letter-spacing: 2px;
            color: #666666;
            // margin: 20px 0;
            // text-indent: 2em;
            .top {
              margin: 10px 0;
            }
          }
          .listMenuOne {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .comy {
            width: 292px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            font-size: 28px;
            background-color: #0087ff;
            opacity: 0.8;
            margin-right: 14px;
            margin-bottom: 20px;
          }
        }
      }
      .news {
        display: flex;
        width: 100%;
        padding: 0 15px 15px;
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        img {
          display: inline-block;
          width: 150px;
          height: 100px;
          margin-top: 15px !important;
        }
        .info {
          flex: 1;
          padding: 0 20px;
          h4 {
            // margin-top: 20px;
            font-size: 16px;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            margin: 15px 0 10px;
          }
          p {
            height: 48px;
            font-size: 14px;
            line-height: 24px;
            // margin-left: 17px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            span {
              color: #666 !important;
            }
          }
          a {
            display: block;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            // margin-left: 15px;
            // margin-top: 21px;
            font-size: 14px;
            color: #fff;
            background-color: #cc1914;
          }
        }
      }
      .page {
        text-align: center;
        margin-bottom: 20px;
      }
      .page_pc {
        display: none;
      }
    }
    .isColors {
      color: #fff;
      background-color: #cc1914;
    }
  }
}
</style>
