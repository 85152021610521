<template>
  <div class="company">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <div class="book">
        <h4>企业概况</h4>
        <div class="info">
          <p>
            兰州市新华书店成立于1953年1月，至今已有68年的光辉历史，位于兰州市城关区通渭路10号，隶属于甘肃省新华书店。直属门店有张掖路门店、兰州新区门店，下设七个分公司：七里河区分公司、西固区分公司、安宁区分公司、红古区分公司、榆中县分公司、皋兰县分公司、永登县分公司。
          </p>
          <p>
            目前，全市分公司在职员工251人，各县（区）分公司共有中心门店及城乡网点9处，营业面积（全市）7664平方米，图书品种（全市）10.4万册。主要经营各类图书、中小学课本、大中专教材、报刊、电子出版物、音像制品，数码、服装、纸张批发零售；幼儿教具、办公用品备；通讯产品及设计等。
          </p>
          <p>
            兰州市分公司多年来承担全市中小学教材教辅以及一般图书的发行工作，是党的主要宣传阵地，一直以来积极为全市人民提供文化服务，全力推进全民阅读，不断为社会精神文明建设作出贡献。
          </p>
          <p>
            近年来，兰州市分公司牢牢把握“双效统一”原则，不断创新经营思路，优化队伍结构，在积极稳步发展主业的前提下，加快实施多元化产业发展的步伐，加大改革创新力度，推动新项目的实施，中心门店改造等项目的有序实施已经形成新的利润增长点，这些举措的落实，有效保障了转型发展、创新业务的顺利实施，推动公司工作不断向好发展。
          </p>
          <el-carousel
            :interval="4000"
            type="card"
            height="450px"
          >
            <el-carousel-item v-for="(item, index) in list" :key="index">
              <div class="box">
                <img :src="item.imgUrl" alt="" />
                <div class="font">
                  <p>{{ item.font }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style lang="less">
.company {
  background-color: #f3f3f3;
  .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .book {
      flex: 1;
      margin-bottom: 20px;
      position: relative;
      margin-top: 20px;
      background-color: #fff;
      .icon {
        position: absolute;
        top: 20px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        font-size: 20px;
        margin-left: 30px;
        margin-top: 20px;
        text-align: center;
      }
      .info {
        font-size: 16px;
        color: #343434;
        padding: 20px;
        background-color: #fff;

        img {
          display: block;
          width: 916px;
          height: 486px;
          // background-color: #b2b2b2;
        }
        p {
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          letter-spacing: 2px;
          color: #666666;
          text-indent: 2em;
        }
        .el-carousel__item .box {
          font-size: 14px;
          opacity: 0.75;
          line-height: 200px;
          margin: 0;
          width: 100%;
          img {
            position: relative;
            height: 90%;
          }

          p {
            position: absolute;
            left: 50%;
            width: 100%;
            height: 50px;
            opacity: 0.75;
            background-color: #fff;
            margin-left: -50%;
            text-align: center;
            line-height: 50px;
            bottom: 0px;
            font-size: 26px;
            color: #333;
          }
        }
        .el-carousel__button {
          background-color: #cc1914;
        }
        .el-carousel__item:nth-child(2n) {
          background-color: #99a9bf;
        }
        .el-carousel__container {
          margin-top: 50px;
        }
        .el-carousel__item:nth-child(2n + 1) {
          background-color: #d3dce6;
        }
      }
    }
  }
}
</style>
