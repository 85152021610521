import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import recommend from '../views/recommend/recommend.vue'
import mien from '../views/mien/mien.vue'
import enterprise from '../views/enterprise/enterprise.vue'
import framework from '../views/framework/framework.vue'
import intro from '../views/intro/intro.vue'
import party from '../views/party/party.vue'
import longyuan from '../views/longyuan/longyuan.vue'
import rankingList from '../views/rankingList/rankingList.vue'
import info from '../views/info/info.vue'
import charactersIntro from '../views/charactersIntro/charactersIntro.vue'
import history from '../views/history/history.vue'
import bookInfo from '../views/bookInfo/bookInfo.vue'
import company from '../views/company/company.vue'
import study from '../views/study/study.vue'
import filiale from '../views/filiale/filiale.vue'
import dsInfo from '../views/dsInfo/dsInfo.vue'
import branchDetails from '../views/branchDetails/branchDetails.vue'
import dynamic from '../views/dynamic/dynamic.vue'
import contact from '../views/contact/contact.vue'
import bookSearch from '../views/bookSearch/bookSearch.vue'
import rankingInfo from '../views/rankingInfo/rankingInfo.vue'
import bookDownload from '../views/bookDownload/bookDownload.vue'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: index
    },
    {
      path: '/',
      redirect: 'index'
    },
    {
      path: '/index',
      component: index
    },
    {
      path: '/recommend',
      component: recommend
    },
    {
      path: '/mien',
      component: mien
    },
    {
      path: '/enterprise',
      component: enterprise
    },
    {
      path: '/framework',
      component: framework
    },
    {
      path: '/intro',
      component: intro
    },
    {
      path: '/party',
      component: party
    },
    {
      path: '/longyuan',
      component: longyuan
    },
    {
      path: '/rankingList',
      component: rankingList
    },
    {
      path: '/info',
      component: info
    },
    {
      path: '/charactersIntro',
      component: charactersIntro
    },
    {
      path: '/history',
      component: history
    },
    {
      path: '/bookInfo',
      component: bookInfo,
      name: 'bookInfo'
    },
    {
      path: '/company',
      component: company
    },
    {
      path: '/study',
      component: study
    },
    {
      path: '/filiale',
      component: filiale
    },
    {
      path: '/dsInfo',
      component: dsInfo
    },
    {
      path: '/branchDetails',
      component: branchDetails
    },
    {
      path: '/dynamic',
      component: dynamic
    },
    {
      path: '/contact',
      component: contact
    },
    {
      path: '/bookSearch',
      component: bookSearch
    },
    {
      path: '/rankingInfo',
      component: rankingInfo
    },
    {
      path: '/bookDownload',
      component: bookDownload
    }
  ]
})

export default router
