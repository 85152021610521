<template>
  <div class="contact">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <div class="book">
        <img
          src="../../assets/img/sddz1.png"
          alt=""
          style="margin-bottom:20px; width:100%"
          class="cont_pc"
        />
        <img
          src="../../assets/img/sddzyidd.png"
          alt=""
          style="margin-bottom:20px; width:100%"
          class="cont_ydd"
        />
        <h4 style="margin-left:380px" class="mgl"><i class="line"></i> 甘肃省新华书店</h4>
        <h4>
          <img src="../../assets/img/location.png" class="t1" />
          地址：甘肃省兰州市安宁区安宁东路68号
        </h4>
        <h4>
          <img src="../../assets/img/email.png" class="t2" />
          邮箱：gstsfx@126.com
        </h4>
      </div>
    </div>

    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { partyOne } from '@/api/myHeader.js'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  created () {
    console.log('uhy', this.$route.query)
    let query = this.$route.query.id
    console.log({ articleId: query })
    partyOne({ articleId: query }).then(res => {
      this.list = res.data.data
      console.log('====', res.data.data)
    })
  },
  data () {
    return {
      list: []
    }
  },
  methods: {}
}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .contact {
    background-color: #f3f3f3;
    .banner {
      // margin-bottom: 90px;
      img {
        width: 100%;
      }
    }
    .content {
      .book {
        width: 1200px;
        margin: 20px auto;
        text-align: center;
        background-color: #fff;
        padding: 20px;
        .cont_ydd {
          display: none;
        }
        h4 {
          position: relative;
          font-family: SourceHanSansCN-Medium;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #2c2b2b;
          padding: 30px 0 30px;
          text-align-last: left;
          margin-left: 360px;
          .t3 {
            margin-left: 380px;
          }
          .line {
            position: absolute;
            top: 29px;
            left: -10px;
            display: inline-block;
            width: 3px;
            height: 25px;
            background-color: #cc1914;
          }
          .t1 {
            display: inline-block;
            width: 30px;
            height: 30px;
          }
          .t2 {
            display: inline-block;
            width: 30px;
            height: 30px;
          }
        }
        span {
          display: block;
          font-family: SourceHanSansCN-Normal;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #999999;
          margin: 0 0 20px;
        }
        p {
          font-family: SourceHanSansCN-Normal;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #666666;
          text-align: left;
          text-indent: 2em;
          // margin: 15px 0;
        }
        .imgBig img {
          display: block;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .contact {
    background-color: #f3f3f3;
    .banner {
      // margin-bottom: 90px;
      img {
        width: 100%;
      }
    }
    .content {
      .book {
        width: 100%;
        margin: 10px auto;
        text-align: center;
        background-color: #fff;
        // padding: 20px;
        .cont_pc {
          display: none;
        }
        h4 {
          position: relative;
          font-family: SourceHanSansCN-Medium;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #2c2b2b;
          padding: 20px 0 20px;
          text-align-last: left;
          margin-left: 10px;
          .line {
            position: absolute;
            top: 20px;
            left: -20px;
            display: inline-block;
            width: 3px;
            height: 25px;
            background-color: #cc1914;
          }
          .t1 {
            display: inline-block;
            width: 30px;
            height: 30px;
          }
          .t2 {
            display: inline-block;
            width: 30px;
            height: 30px;
          }
        }
        .mgl {
          font-size: 20px;
          margin-left: 43px !important;
        }
        span {
          display: block;
          font-family: SourceHanSansCN-Normal;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #999999;
          margin: 0 0 20px;
        }
        p {
          font-family: SourceHanSansCN-Normal;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #666666;
          text-align: left;
          text-indent: 2em;
          // margin: 15px 0;
        }
        .imgBig img {
          display: block;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
