<template>
  <div class="navBar">
    <ul class="nav">
      <li>
        <img class="item" src="../assets/img/分类.png" alt="" />
        <span class="t1">企业风貌</span>
        <span class="t2">ENTERPRISE STYLE</span>
      </li>
      <li
        :class="{ active:    isActive == index  }"
        v-for="(item, index) in list1"
        :key="index"
        @click="changeClass(index, item.com)"
      >
        <img class="icon" :src="item.imgUrl" alt="" />
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <div class="telephone">
      <!-- <img src="../assets/img/tel.png" alt="" />
      <p class="t3">24小时服务热线 :</p>
      <p class="t4">0931-8426312</p> -->
      <img src="../assets/img/ewm.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isActive: 0,
      imgUrl: require('@/assets/img/白色箭头.png'),
      list1: [
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '企业简介',
          com: '/intro'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '子公司简介',
          com: '/filiale'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '领导班子',
          com: '/enterprise'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '组织架构',
          com: '/framework'
        }
      ]
    }
  },
  created() {
    this.isActive = sessionStorage.getItem("activeNo")
  },
  methods: {
    changeClass (index, com) {
      sessionStorage.setItem('activeNo', index)
      // this.isActive = index
      this.$router.push(com)
      this.list1.forEach(item => {
        item.imgUrl = require('@/assets/img/黑色箭头.png')
              this.list1[index].imgUrl = require('@/assets/img/白色箭头.png')

      })
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="less">
.nav {
  width: 225px;
  height: 370px;
  color: #343434;
  background-color: #fff;
  margin-right: 35px;
  margin-top: 20px;
  li {
    position: relative;
    width: 225px;
    height: 70px;
    line-height: 70px;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 20px;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      position: absolute;
      top: 25px;
      left: 20px;
      background: url('~@/assets/img/黑色箭头.png') no-repeat;
      background-size: 100%;
    }
    &:nth-child(n + 2):hover {
      color: #fff;
      background-color: #cc1914;
      .icon {
        background: url('~@/assets/img/白色箭头.png') no-repeat;
        background-size: 100%;
      }
    }
    span {
      font-size: 16px;
      font-weight: 700;
      margin-left: 45px;
    }
  }
  li:nth-child(1) {
    width: 100%;
    height: 90px;
    padding-top: 32px;
    text-align: left;
    line-height: 0;
    .item {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 31px;
      left: 20px;
    }
    .t1 {
      margin-left: 42px;
      margin-right: 30px;
    }
    .t2 {
      display: inline-block;
      font-size: 14px;
      margin-left: 42px;
      margin-top: 20px;
    }
  }
}
.telephone {
  position: absolute;
  top: 400px;
  left: 0;
  width: 225px;
  height: 160px;
  text-align: center;
  color: #fff;
  background-color: #cc1914;
  img {
    display: block;
    width: 225px;
    height: 225px;
  }
  .t3 {
    font-size: 16px;
  }
  .t4 {
    font-size: 22px;
    margin-top: 10px;
  }
}
</style>
