<template>
  <div class="intro">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="banner"></div>
    <div class="content">
      <navBar class="navBar"></navBar>
      <div class="book">
        <img src="../../assets/img/企业风貌.jpg" alt="" class="imgBar" />
        <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
        <div class="url1">
        <img class="icon" src="@/assets/img/红色箭头.png" alt="" />
        <h4>企业简介</h4></div>
        <div class="info" v-for="(item, index) in companyList" :key="index">
          <p v-html="item.companyName"></p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import navBar from '../navBar'
import { listCompanyTT, getMenuList2 } from '@/api/myHeader.js'

export default {
  components: {
    // myHeader,
    // myFooter,
    navBar
  },
  created () {
    this.party()
    getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[1].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[1].comumns)
    })
  },
  data () {
    return {
      // 企业简介表格数据
      isActives: 0,
      companyList: [],
      getMenuList2: [],
      menuList3: []
    }
  },
  methods: {
    party () {
      listCompanyTT(this.queryParams).then(res => {
        this.companyList = res.data.data
      })
    },
    toCompany () {
      this.$router.push('/company')
    },
    toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    }
  }
}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .intro {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      .book {
        flex: 1;
        margin-bottom: 20px;
        position: relative;
        margin-top: 20px;
        background-color: #fff;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: none;
        }
        .icon {
          position: absolute;
          top: 393px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          // margin-bottom: 20px;
          margin-top: 20px;
        }
        .el-table tr {
          font-size: 16px;
          color: #343434;
          text-align: center;
        }
        .info {
          font-size: 16px;
          color: #343434;
          padding: 20px;
          background-color: #fff;

          img {
            display: block;
            width: 916px;
            height: 486px;
            // background-color: #b2b2b2;
          }
          p {
            width: 918px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            // letter-spacing: 2px;
            color: #666666;
            // margin: 20px 0;
            text-indent: 2em;
            .top {
              margin: 20px 0;
            }
          }
          .filiale {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .comy {
            width: 292px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            font-size: 28px;
            background-color: #0087ff;
            opacity: 0.8;
            margin-right: 14px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .intro {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      // display: flex;
      .navBar {
        display: none !important;
      }
      .book {
        // flex: 1;
        margin-bottom: 20px;
        position: relative;
        // margin-top: 20px;
        background-color: #fff;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
          }
        }
        .url1 {
          position: relative;
          .icon {
            position: absolute;
            top: 0px;
            left: 0;
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 30px;
          }
          h4 {
            color: #d13632;
            font-size: 20px;
            margin-left: 30px;
            // margin-bottom: 20px;
            margin-top: 20px;
          }
        }
        .el-table tr {
          font-size: 16px;
          color: #343434;
          text-align: center;
        }
        .info {
          font-size: 16px;
          color: #343434;
          padding: 10px;
          background-color: #fff;

          img {
            display: block;
            width: 916px;
            height: 486px;
            // background-color: #b2b2b2;
          }
          p {
            // width: 918px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            // letter-spacing: 2px;
            color: #666666;
            // margin: 20px 0;
            text-indent: 2em;
            .top {
              margin: 20px 0;
            }
          }
          .filiale {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }
          .comy {
            width: 292px;
            height: 70px;
            line-height: 70px;
            text-align: center;
            color: #fff;
            font-size: 28px;
            background-color: #0087ff;
            opacity: 0.8;
            margin-right: 14px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .isColors {
      color: #fff;
      background-color: #cc1914;
    }
  }
}
</style>
