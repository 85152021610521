<template>
  <div class="info mat">
    <!-- 头部 -->
    <img :src="list.photo3" v-if="list.photo3" style="width: 1px; height:1px;"/>
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <div class="book">
        <h4>
          {{ list.menuName }}
        </h4>
        <span class="time"> 发布时间:{{ list.releaseTime }} </span>
        <p v-html="list.bookContent" class="imgBig">
          <!-- <p v-html="cutout(list.bookContent.photo3)" class="imgBig"> -->
        </p>
        <div v-if="attachments.length > 0" style="text-align: left;font-size: 16px;color: #999999;">
          <div>附件：</div>
          <div style="display: flex; flex-direction: column;padding-left: 20px;">
            <a :href="`/profile/${i.url}`" v-for="i in attachments" :key="i.name">{{i.name}}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import {partyOne} from "@/api/myHeader.js";

export default {
  components: {
    // myHeader,
    // myFooter
  },
  created() {
    console.log("uhy", this.$route.query);
    let query = this.$route.query.id;
    console.log({articleId: query});
    partyOne({articleId: query}).then((res) => {
      this.list = res.data.data;
      console.log("====", res.data.data);
      if (this.list.menuName) {
        document.title = `${this.list.menuName} - 甘肃新华书店`
      }
      if (this.list.attachments) {
        this.attachments = JSON.parse(this.list.attachments)
      }

    });
  },
  beforeDestroy() {
    document.title = `甘肃新华书店`
  },
  data() {
    return {
      list: [],
      attachments: []
    };
  },
  methods: {},
};
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .mat {
    margin: 20px 0;
  }

  .info {
    background-color: #f3f3f3;

    .content {
      font-family: "Mircosoft Yahei";
      line-height: 30px !important;
      font-size: 20px;

      .book {
        width: 1200px;
        margin: 0px auto;
        text-align: center;
        background-color: #fff;
        padding: 20px;

        h4 {
          font-size: 32px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: 1px;
          color: #2c2b2b;
          padding: 50px 0 30px;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: 1px;
          color: #999999;
          margin: 0 0 20px;
        }

        p {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2;
          letter-spacing: 1px;
          color: #666666;
          text-align: left;
          text-indent: 2em;
          // margin: 15px 0;
          span {
            display: inline-block;
            line-height: 2;
            letter-spacing: 0px;
            color: #999999;
            margin: 0;
            padding-right: 4em;
          }
        }

        .imgBig img {
          display: block;
          width: 650px;
          height: 100%;
          margin: 20px auto;
        }
      }
    }
  }
}

// 移动端
@media only screen and (max-width: 768px) {
  .info {
    background-color: #f3f3f3;

    .content {
      font-family: "Mircosoft Yahei";

      .book {
        width: 100%;
        margin: 0px auto;
        text-align: center;
        background-color: #fff;
        padding: 20px 10px;

        h4 {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #2c2b2b;
          padding: 0px 0 30px;
        }

        span {
          display: block;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #999999;
          margin: 0 0 20px;
        }

        p {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 21px;
          letter-spacing: 1px;
          color: #666666;
          text-align: left;
          text-indent: 2em;

          // margin: 15px 0;
          span {
            display: inline-block;
            line-height: 20px;
            letter-spacing: 0px;
            color: #999999;
            margin: 0;
            padding-right: 4em;
          }
        }

        .imgBig img {
          display: block;
          width: 82%;
          margin: 20px auto;
        }
      }
    }
  }
}
</style>
