import _fetch from './request.js'
// 企业风貌
function getMenuList2 () {
  return _fetch({
    url: '/web/method/getlist1'
  })
}
// 时尚阅读
function getMenuList3 (params) {
  return _fetch({
    url: '/web/method/getlist3',
    params
  })
}
// 新华传承
function getMenuList4 (params) {
  return _fetch({
    url: '/web/method/getlist4',
    params
  })
}
// 党建引领
function party (menuId) {
  return _fetch({
    url: 'web/method/getlist2?menuId=' + menuId
  })
}

// 党建引领
function partyTui (menuId) {
  return _fetch({
    url: 'web/method/getlist2Tui?menuId=' + menuId
  })
}
// 门店风采
function partyTuiOne (menuId) {
  return _fetch({
    url: 'web/method/getlist2Mentu?menuId=' + menuId
  })
}
// 门店风采
function partyTuiTwo (menuId) {
  return _fetch({
    url: 'web/method/getlist2Men?menuId=' + menuId
  })
}

function partyOne (params) {
  return _fetch({
    url: 'web/method/listXiang',
    params
  })
}

function listCompanyTT (query) {
  return _fetch({
    url: '/open/interface/listCompanyId',
    method: 'get',
    params: query
  })
}
// 查询领导班子列表
function listLeadershipTT (query) {
  return _fetch({
    url: '/open/interface/listTbLeadership',
    method: 'get',
    params: query
  })
}

// 查询组织架构列表
function listOrganizationTT (query) {
  return _fetch({
    url: '/open/interface/listTbOrganization',
    method: 'get',
    params: query
  })
}

//新书推荐
function listBookTT (query) {
  return _fetch({
    url: '/open/interface/listTbBook',
    method: 'get',
    params: query
  })
}

//新书推荐
function listBookDs (query) {
  return _fetch({
    url: '/open/interface/listTbParty',
    method: 'get',
    params: query
  })
}

//首页时尚阅读查询
function listBookwm (query) {
  return _fetch({
    url: '/open/interface/listTbBooktt',
    method: 'get',
    params: query
  })
}

function getBookTT (id) {
  return _fetch({
    url: '/open/interface/' + id,
    method: 'get'
  })
}

function getBookSS (id) {
  return _fetch({
    url: '/open/interface/dsId?id=' + id,
    method: 'get'
  })
}

// 各市分公司
function listMenuOne (query) {
  return _fetch({
    url: '/open/interface/listTbMenuone',
    method: 'get',
    params: query
  })
}
// 分公司详情
function listCityOne (menuId) {
  return _fetch({
    url: '/open/interface/menuId?menuId=' + menuId
  })
}
// 排行榜
function rankingList (menuId) {
  return _fetch({
    url: '/open/interface/listTbLeaderboard' + menuId
  })
}
// 查询书籍查询列表
function listSelectbook (query) {
  return _fetch({
    url: '/open/interface/listTbSelectbook',
    method: 'get',
    params: query
  })
}
function listSort (query) {
  return _fetch({
    url: '/open/interface/listTbSort',
    method: 'get',
    params: query
  })
}
function listLeaderboard (query) {
  return _fetch({
    url: '/open/interface/listTbLeaderboard',
    method: 'get',
    params: query
  })
}
// 排行榜
function rankingInfo (id) {
  return _fetch({
    url: '/open/interface/tbLeaderboardId?id=' + id
  })
}
// 书目下载
function listFileupload() {
  return _fetch({
    url: '/open/interface/listTbFileupload',
    method: 'get',
  })
}
// 首页轮播图
function listTbCarousel() {
  return _fetch({
    url: '/open/interface/listTbCarousel',
    method: 'get',
  })
}
export {
  listTbCarousel,
  listFileupload,
  rankingInfo,
  listLeaderboard,
  getMenuList2,
  getMenuList3,
  listBookDs,
  partyTuiOne,
  partyTuiTwo,
  getBookTT,
  getBookSS,
  partyTui,
  getMenuList4,
  listBookwm,
  party,
  partyOne,
  listCompanyTT,
  listLeadershipTT,
  listOrganizationTT,
  listBookTT,
  listMenuOne,
  listCityOne,
  rankingList,
  listSelectbook,
  listSort
}
