<template>
  <!--footer-->
  <div class="footer">
    <div class="main">
      <div class="left">
        <div class="box">
          <h5>企业风貌</h5>
          <p><a href="#">企业简介</a></p>
          <p><a href="#">领导班子</a></p>
          <p><a href="#">组织机构</a></p>
        </div>
        <div class="box">
          <h5>时尚阅读</h5>
          <p><a href="#">新书推荐</a></p>
          <p><a href="#">排行榜</a></p>
        </div>
        <div class="box">
          <h5>联系我们</h5>
          <p>联系电话: 0931-8426812</p>
          <p>地址:兰州市安宁区安宁东路68号</p>
        </div>
      </div>
      <div class="right">
        <div class="box">
          <img src="@/assets/img/ewm.jpg" alt="" class="ewm" />
          <p>甘肃省新华书店</p>
        </div>
        <div class="box">
          <img src="@/assets/img/xbsc.jpg" alt=""  class="ewm" />
          <p>西北书城公众号</p>
        </div>
        <div class="box">
          <img src="@/assets/img/TmallStore.png" alt="" />
          <p>网上商城</p>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <h5><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010502001634">联网备案号 62010502001634</a> 丨 <a href="https://beian.miit.gov.cn/">工信部备案号 陇ICP备20001858号-1</a></h5>
      <h5>Copyright © 2023甘肃省新华书店</h5>
      <div style="position:absolute; right:120px; top:50%; transform: translateY(-50%)">
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1748325248991715328&language=CN&certType=8&url=*.gsxhsd.com" target="_blank" style="position: relative;display: inline-block;height: 38px;">
          <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1748325248991715328=*.gsxhsd.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
.footer {
  width: 100%;
  background-color: #cc1914;
  .main {
    display: flex;
    justify-content: space-around;
    width: 1200px;
    height: 205px;
    margin: 0 auto;
    // background-color: #00f;
    padding-top: 40px;
    .left {
      display: flex;
      // background-color: pink;
      width: 50%;
      height: 100%;
      .box {
        color: #fff;
        margin-right: 95px;

        // background-color: blue;
        h5 {
          font-size: 16px;
          margin-bottom: 15px;
        }
        p {
          line-height: 24px;
        }
        a {
          color: #fff;
        }
      }
    }
    .right {
      // background-color: skyblue;
      display: flex;
      text-align: center;
      width: 50%;
      height: 100%;
      .box {
        margin-right: 30px;
        p {
          color: #fff;
          margin: 15px 0;
        }
        .ewm {
          width: 126px;
          height: 126px;
        }
      }
    }
  }
  .footer-box {
    width: 100%;
    border-top: 2px solid #fff;
    padding-bottom: 1px;
    text-align: center;
    position: relative;
    h5 {
      font-size: 16px;
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
}
// 移动端
@media only screen and (max-width: 768px) {
.footer {
  width: 100%;
  height: 60px;
  background-color: #cc1914;
      border-top: 2px solid #fff;

  .main {
    display: none;
  }
  .footer-box {
    width: 100%;
    // height: 85px;
    text-align: center;
    h5 {
      font-size: 16px;
      color: #fff;
      // padding-right: 180px;
    }
  }
}
}
</style>
