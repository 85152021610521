<template>
  <div class="charactersIntro">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <ul class="nav">
        <li>
          <img class="item" src="../../assets/img/分类.png" alt="" />
          <span class="t1">新华传承</span>
          <span class="t2">XINHUA HERITAGE</span>
        </li>
        <li
          :class="{ active: index == isActive }"
          v-for="(item, index) in list1"
          :key="index"
          @click="changeClass(item.id)"
        >
          <img class="icon" :src="item.imgUrl" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="telephone">
        <!-- <img src="../../assets/img/tel.png" alt="" />
        <p class="t3">24小时服务热线 :</p>
        <p class="t4">023-1234-5678</p> -->
        <img src="../../assets/img/ewm.jpg" alt="" />
      </div>

      <div class="book">
        <img src="../../assets/img/新华传承.jpg" alt="" class="imgBar" />
        <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
        <div class="url1">
          <img class="icon" src="@/assets/img/白色箭头.png" alt="" />
          <h4>新华人物事迹</h4>
        </div>
        <div class="person" v-for="(item, idx) in personListPage" :key="idx">
          <div class="personBox" style="margin-bottom:10px">
            <span>{{ item.name }}</span>
            <em>{{ item.position }}</em>
          </div>
          <p v-html="item.nameJs"></p>
        </div>
        <div class="page">
          <el-pagination
            :total="pagination.total"
            :page-size="everypageNum"
            :current-page="pagination.currentPage"
            @current-change="currentChange"
            layout="total,prev,pager,next,jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { listXinhua, listXinhuaOne } from '@/api/charactersIntro.js'
import { getMenuList2 } from '@/api/myHeader.js'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  created () {
        this.isActive = sessionStorage.getItem('activeNo')

    getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[7].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[6].comumns)
    })
  },
  mounted () {
    listXinhua().then(res => {
      // window.console.log('111', res.data.rows)
      this.personList = res.data.rows
      this.personListPage = this.personList.slice(
        this.pageVal - 1,
        this.everypageNum
      )
      // this.personListPage = this.personList.slice(0, 10)
      this.pagination.total = res.data.rows.length
      // console.log('1221', this.personList)
    })
  },
  data () {
    return {
      personListPage: [],
      isActive: 0,
      menuList3: [],
      isActives: 1,
      imgUrl: require('@/assets/img/黑色箭头.png'),
      list1: [
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '新华历史',
          id: 0
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '新华人物事迹',
          id: 1
        }
      ],
      pagination: {
        total: 0,
        pageSize: 5,
        // sizes: [10, 20, 50],
        currentPage: 1
      },

      personList: [],
      everypageNum: 5, //每一页显示多少条
      pageVal: 1
    }
  },
  methods: {
    changeClass (id) {
            // sessionStorage.setItem('activeNo', index)
      // console.log('dac', id)
      // this.isActive = id
      this.list1.forEach(item => {
        item.imgUrl = require('@/assets/img/黑色箭头.png')
        // alert(id)
        if (id === 1) {
          //人物事迹
          this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
        } else if (id === 0) {
          //新华历史
          this.$router.push({ path: '/history', query: { menuId: id } })
        }
      })
      this.list1[id].imgUrl = require('@/assets/img/白色箭头.png')
      listXinhuaOne()
    },
    toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },
    // 页码改变
    currentChange (val) {
      this.pageVal = val //此处为用户点击第几页
      // 1: 0,19  当用户点击第一页，那么就是前20条，对耶下标位置应为0至19
      //后面截取的位置
      var that = this
      let start = that.everypageNum * val - 5
      let end = that.everypageNum * val
      if (that.personList.length <= that.everypageNum) {
        //这种情况为只有20条或者20条以下数据的情况
        that.personListPage = that.personList
      } else {
        //这种情况为大于20条数据
        if (that.everypageNum * val >= that.personList.length) {
          //用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
          that.personListPage = that.personList.slice(start)
        } else {
          //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
          that.personListPage = that.personList.slice(start, end)
        }
      }
    }
  }
}
</script>

<style lang="less">
// PC端
@media only screen and (min-width: 768px) {
  .charactersIntro {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        width: 225px;
        height: 230px;
        color: #343434;
        background-color: #fff;
        margin-right: 35px;
        li {
          position: relative;
          width: 100%;
          height: 70px;
          line-height: 70px;
          border-bottom: 1px solid #e6e6e6;
          padding-left: 20px;
          .icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 15px;
            position: absolute;
            top: 25px;
            left: 20px;
            // background: url('~@/assets/img/黑色箭头.png') no-repeat;
            // background-size: 100%;
          }
          &:nth-child(n + 2):hover {
            color: #fff;
            background-color: #cc1914;
            .icon {
              background: url('~@/assets/img/白色箭头.png') no-repeat;
              background-size: 100%;
            }
          }
          span {
            font-size: 16px;
            font-weight: 700;
            margin-left: 45px;
          }
        }
        li:nth-child(1) {
          width: 100%;
          height: 90px;
          padding-top: 32px;
          text-align: left;
          line-height: 0;
          .item {
            width: 26px;
            height: 26px;
            position: absolute;
            top: 31px;
            left: 20px;
          }
          .t1 {
            margin-left: 42px;
            margin-right: 30px;
          }
          .t2 {
            display: inline-block;
            font-size: 14px;
            margin-left: 42px;
            margin-top: 20px;
          }
        }
      }
      .telephone {
        position: absolute;
        top: 260px;
        left: 0;
        width: 225px;
        height: 160px;
        text-align: center;
        color: #fff;
        background-color: #cc1914;
        img {
          display: block;
          width: 225px;
          height: 225px;
          // margin: 30px 0 10px 90px;
        }
        .t3 {
          font-size: 16px;
        }
        .t4 {
          font-size: 22px;
          margin-top: 10px;
        }
      }
      .book {
        flex: 1;
        // background-color: pink;
        margin-bottom: 40px;
        position: relative;
        background-color: #fff;
        margin-top: 20px;
        .imgBar {
          width: 100%;
        }
        .menuCross {
          display: none;
        }
        .icon {
          position: absolute;
          top: 370px;
          left: 0px;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 20px;
          margin-top: 5px;
        }
        .person {
          padding: 0 20px 20px;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #d5d7d8;
          span {
            font-weight: 700;
          }
          span,
          em {
            font-size: 16px;
            font-stretch: normal;
            line-height: 21px;
            letter-spacing: 1px;
            color: #2c2b2b;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          p {
            // margin-top: 10px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 21px;
            color: #666666;
            text-align: 2em;
          }
        }
        .page {
          width: 390px;
          height: 30px;
          margin-left: 170px;
          // background-color: #f60;
          .el-pager {
            // width: 390px;
            height: 30px;
            li {
              width: 37px;
              height: 30px;
              margin: 0;
              border: 1px solid #e6e6e6;
            }
          }
        }
      }
    }
  }
}
// 移动端
@media only screen and (max-width: 768px) {
  .charactersIntro {
    background-color: #f3f3f3;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: flex;
      // background-color: #f0f;
      .nav {
        display: none;
      }
      .telephone {
        display: none;
      }
      .book {
        // flex: 1;
        // background-color: pink;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
        background-color: #fff;
        img {
          width: 100%;
        }
        .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
            overflow: hidden;
            margin: 0;
          }
        }
        .url1 {
          position: relative;
        .icon {
          position: absolute;
          top: 0px;
          left: 0;
          display: inline-block;
          width: 22px;
          height: 22px;
          margin-right: 30px;
        }
        h4 {
          color: #d13632;
          font-size: 20px;
          margin-left: 30px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        }
        .person {
          padding: 0 10px 20px;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #d5d7d8;
          background-color: #fff;
          span {
            font-weight: 700;
          }
          span,
          em {
            font-size: 16px;
            font-stretch: normal;
            line-height: 21px;
            letter-spacing: 1px;
            color: #2c2b2b;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          p {
            // margin-top: 10px;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 25px;
            color: #666666;
            text-indent: 2em;
          }
        }
        .el-pagination {
          text-align: center;
        }
        .el-pagination__jump {
          display: none;
        }
        .el-pagination__total {
          display: none;
        }
      }
    }
    .isColors {
      color: #fff;
      background-color: #cc1914;
    }
  }
}
</style>
