<template>
  <div class="bookSearch">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <ul class="nav">
        <li>
          <img class="item" src="../../assets/img/分类.png" alt="" />
          <span class="t1">时尚阅读</span>
          <span class="t2">FASHION READING</span>
        </li>
        <li
          :class="{ active: isActive == index}"
          v-for="(item, index) in list1"
          :key="index"
          @click="changeClass(index, item.com)"
        >
          <img class="icon" :src="item.imgUrl" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="telephone">
        <img src="../../assets/img/ewm.jpg" alt="" />
      </div>
      <div class="book">
                <img src="../../assets/img/时尚阅读.jpg" alt="" class="imgBar" />
        <ul class="menuCross">
          <li
            v-for="(item, index) in menuList3"
            :key="index"
            @click="toIndexTwo(item.id, index)"
          >
            <div class="item-lit" :class="{ isColors: isActives === index }">
              {{ item.value }}
            </div>
          </li>
        </ul>
        <div class="url1">
        <img class="icon1" src="@/assets/img/白色箭头.png" alt="" />
        <h4>书籍查询</h4></div>
        <el-form label-width="80px" :model="formName" ref="formName" class="search-pc">
          <el-row >
            <el-col :span="12">
              <el-form-item label="书名:" prop="pm">
                <el-input  v-model="formName.pm"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="书号:" prop="spbh">
                <el-input v-model="formName.spbh"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="定价:" prop="dj">
                <el-input v-model="formName.dj"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出版社:" prop="cbs" >
                <el-input v-model="formName.cbs"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="库存册数:" prop="kccs">
                <el-input v-model="formName.kccs"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="text-align: center;">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              style="margin-right:50px"
              >查询</el-button
            >
            <el-button style="margin-left:50px" @click="resetForm('formName')"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <el-form label-width="80px" :model="formName" ref="formName" class="search-ydd">
          <el-row >
            <el-col :span="23">
              <el-form-item label="书名:" prop="pm">
                <el-input  v-model="formName.pm"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23">
              <el-form-item label="书号:" prop="spbh">
                <el-input v-model="formName.spbh"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="定价:" prop="dj">
                <el-input v-model="formName.dj"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23">
              <el-form-item label="出版社:" prop="cbs" >
                <el-input v-model="formName.cbs"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="23">
              <el-form-item label="库存册数:" prop="kccs">
                <el-input v-model="formName.kccs"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="text-align: center;">
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              style="margin-right:50px"
              >查询</el-button
            >
            <el-button style="margin-left:50px" @click="resetForm('formName')"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <el-table :data="tableData" height="383" border style="width: 100%;"
        :header-cell-style="{'text-align':'center'}"
        >
          <el-table-column prop="pm" label="书名"  width="150">
          </el-table-column>
          <el-table-column prop="spbh" label="书号" width="150">
          </el-table-column>
          <el-table-column prop="dj" label="定价" width="120">
          </el-table-column>
          <el-table-column prop="cbs" label="出版社" width="180">
          </el-table-column>
          <el-table-column prop="cbny" label="出版日期" width="120">
          </el-table-column>
          <el-table-column prop="kccs" label="库存册数" width="100">
          </el-table-column>
          <el-table-column prop="ztid" label="门店" width="100">
          </el-table-column>
        </el-table>
        <!-- <div class="page">
          <el-pagination
            :total="pagination.total"
            :page-size="everypageNum"
            :current-page="pagination.currentPage"
            @current-change="currentChange"
            layout="total,prev,pager,next,jumper"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
import { listSelectbook, getMenuList2 } from '@/api/myHeader.js'
export default {
  data () {
    return {
      dsListPage: [],
      page: '',
      limit: '',
      dsList: [],
      isActive: 0,
      formName: {
        pm: '',
        region: '',
        type: '',
        writer: '',
        spbh: '',
        cbs: '',
        dj: '',
        cbny: '',
        kccs: ''
      },
      tableData: [
      ],
      imgUrl: require('@/assets/img/黑色箭头.png'),
      list1: [
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '党史学习教育书目推荐',
          idzt: 0,
          com: '/study'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '新书推荐',
          idzt: 1,
          com: '/recommend'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '排行榜',
          idzt: 2,
          com: '/rankingList'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '书籍查询',
          idzt: 3,
          com: '/bookSearch'
        },
        {
          imgUrl: require('@/assets/img/黑色箭头.png'),
          name: '书目下载',
          idzt: 4,
          com: '/bookDownload'
        }
      ],
      menuList3: [],
      isActives: 3,
      pagination: {
        total: 0,
        pageSize: 5,
        currentPage: 1
      },
      everypageNum: 5, //每一页显示多少条
      pageVal: 1
    }
  },
  created() {
     this.isActive = sessionStorage.getItem("activeNo")
      getMenuList2().then(res => {
      // console.log('====', res.data)
      this.getMenuList2 = res.data.data
      this.menuList3 = res.data.data[6].comumns
      // console.log('====1', this.getMenuList2[1].comumns)
      window.console.log('1234', res.data.data[6].comumns)
    })
  },
  methods: {
    changeClass (index, com) {
      sessionStorage.setItem('activeNo', index)
      // this.isActive = index
      this.$router.push(com)
      this.list1.forEach(item => {
        item.imgUrl = require('@/assets/img/黑色箭头.png')
      })
      this.list1[index].imgUrl = require('@/assets/img/白色箭头.png')
      this.$forceUpdate()
    },
            toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },

    submitForm () {
      this.$refs.formName.validate(valid => {
        if (valid) {
          listSelectbook(this.formName).then(res=> {
                        console.log('neirong',this.formName)
            // console.log('neirong',res.data)
            this.tableData = res.data.rows
                       console.log('data',this.tableData)

          })
        } else {
          alert('输入有误,请重新输入')
          return false
        }
      })
    },

    resetForm (formName) {
      console.log(formName, this.$refs[formName])
      this.$refs[formName].resetFields()
    }
  },
  // 页码改变
  currentChange (val) {
    this.pageVal = val //此处为用户点击第几页
    // 1: 0,19  当用户点击第一页，那么就是前20条，对耶下标位置应为0至19
    //后面截取的位置
    var that = this
    let start = that.everypageNum * val - 5
    let end = that.everypageNum * val
    if (that.dsList.length <= that.everypageNum) {
      //这种情况为只有20条或者20条以下数据的情况
      that.dsListPage = that.dsList
    } else {
      //这种情况为大于20条数据
      if (that.everypageNum * val >= that.dsList.length) {
        //用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
        that.dsListPage = that.dsList.slice(start)
      } else {
        //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
        that.dsListPage = that.dsList.slice(start, end)
      }
    }
  }
}
</script>

<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
.bookSearch {
  background-color: #f3f3f3;
  .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // background-color: #f0f;
    .nav {
      width: 245px;
      height: 440px;
      color: #343434;
      background-color: #fff;
      margin-right: 35px;
      margin-top: 20px;
      li {
        position: relative;
        width: 100%;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid #e6e6e6;
        padding-left: 20px;
        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
          position: absolute;
          top: 25px;
          left: 20px;
          // background: url('~@/assets/img/黑色箭头.png') no-repeat;
          // background-size: 100%;
        }
        &:nth-child(n + 2):hover {
          color: #fff;
          background-color: #cc1914;
          .icon {
            background: url('~@/assets/img/白色箭头.png') no-repeat;
            background-size: 100%;
          }
        }
        span {
          font-size: 16px;
          font-weight: 700;
          margin-left: 45px;
        }
      }
      li:nth-child(1) {
        width: 100%;
        height: 90px;
        padding-top: 32px;
        text-align: left;
        line-height: 0;
        .item {
          width: 26px;
          height: 26px;
          position: absolute;
          top: 31px;
          left: 20px;
        }
        .t1 {
          margin-left: 42px;
          margin-right: 30px;
        }
        .t2 {
          display: inline-block;
          font-size: 14px;
          margin-left: 42px;
          margin-top: 20px;
        }
      }
    }
    .telephone {
      position: absolute;
      top: 470px;
      left: 0;
      width: 245px;
      height: 160px;
      text-align: center;
      color: #fff;
      background-color: #cc1914;

      img {
        display: block;
        width: 245px;
        height: 225px;
      }
      .t3 {
        font-size: 16px;
      }
      .t4 {
        font-size: 22px;
        margin-top: 10px;
      }
    }
    .book {
      flex: 1;
      // background-color: pink;
      margin-bottom: 40px;
      position: relative;
      margin-top: 20px;
      /* background-color: #f60; */
      .search-ydd,.menuCross,.imgBar {
        display: none;
      }
       
      .imgBar {
        width: 100%;
      }
      .icon1 {
        position: absolute;
        top: 4px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        color: #d13632;
        font-size: 20px;
        margin-left: 30px;
        margin-bottom: 20px;
        margin-top: 5px;
      }
      .bookBox {
        width: 100%;
        padding: 20px;
        background-color: #fff;
        .bookInfo {
          display: flex;
          width: 100%;
          height: 280px;
          background-color: #fff;
          margin-bottom: 20px;
          border-bottom: 1px solid #d7d9da;
          img {
            width: 185px;
            height: 261px;
          }
          .bookInfoBox {
            margin-left: 25px;
            h5 {
              font-family: SourceHanSansCN-Regular;
              font-size: 16px;
              font-weight: 700;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #cc1914;
            }
            .cont {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #666666;
              text-indent: 2em;
              margin-bottom: 20px;
            }
            p {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #999999;
              i {
                margin-right: 30px;
              }
            }
          }
        }
        .page {
          width: 390px;
          height: 30px;
          margin: auto;
          // background-color: #f60;
          .el-pager {
            // width: 390px;
            height: 30px;
            li {
              width: 37px;
              height: 30px;
              margin: 0;
              border: 1px solid #e6e6e6;
            }
          }
        }
      }
    }
  }
}
}
// 移动端
@media only screen and (max-width: 768px) {
  .bookSearch {
  background-color: #f3f3f3;
  .content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    // background-color: #f0f;
    .nav,.telephone {
      display: none;
    }
    .book {
      flex: 1;
      // background-color: pink;
      margin-bottom: 20px;
      width: 100%;
      position: relative;
      // margin-top: 20px;
      /* background-color: #f60; */
      .search-pc {
        display: none;
      }
              .menuCross {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          li {
            width: 110px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            // color: #fff;
            background-color: #f5f5f5;
            overflow: hidden;
            margin: 0;
          }
        }

      .imgBar {
        width: 100%;
      }
      .url1 {
        position: relative;
      .icon1 {
        position: absolute;
        top: 0px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        color: #d13632;
        font-size: 20px;
        margin-left: 30px;
        margin-bottom: 20px;
        margin-top: 5px;
      }
      }
      .bookBox {
        width: 100%;
        padding: 20px;
        background-color: #fff;
        .bookInfo {
          display: flex;
          width: 100%;
          height: 280px;
          background-color: #fff;
          margin-bottom: 20px;
          border-bottom: 1px solid #d7d9da;
          img {
            width: 185px;
            height: 261px;
          }
          .bookInfoBox {
            margin-left: 25px;
            h5 {
              font-family: SourceHanSansCN-Regular;
              font-size: 16px;
              font-weight: 700;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #cc1914;
            }
            .cont {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #666666;
              text-indent: 2em;
              margin-bottom: 20px;
            }
            p {
              font-family: SourceHanSansCN-Regular;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              line-height: 21px;
              letter-spacing: 1px;
              color: #999999;
              i {
                margin-right: 30px;
              }
            }
          }
        }
        .page {
          width: 390px;
          height: 30px;
          margin: auto;
          // background-color: #f60;
          .el-pager {
            // width: 390px;
            height: 30px;
            li {
              width: 37px;
              height: 30px;
              margin: 0;
              border: 1px solid #e6e6e6;
            }
          }
        }
      }
    }
        .isColors {
      color: #fff;
      background-color: #cc1914;
    }

  }
}
.active {
    color: #fff;
    background-color: #cc1914;
}

}
</style>
