<template>
  <div class="branch">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->
    <div class="content">
      <div class="book">
        <div class="info">
            <h4 style="text-align:center; margin-bottom:30px; color:#000">{{companyList.pare4}}</h4>
            <img :src="companyList.pare5" alt="" class="imgClass">
          <p v-html="companyList.companyName"></p>
          <!-- <p v-html="item.companyName"></p> -->
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import { listCityOne } from '@/api/myHeader.js'
export default {
  components: {
    // myHeader,
    // myFooter
  },
  created () {
    this.party()
  },
  data () {
    return {
      // 企业简介表格数据
      companyList: []
    }
  },
  methods: {
    party () {
      let id = this.$route.query.id
      listCityOne(id).then(res => {
        this.companyList = res.data.data
        // console.log('分公司', res.data.data)
      })
    }
  }
}
</script>

<style lang="less">
// PC端
@media only screen and (min-width: 768px) {
.branch {
  background-color: #f3f3f3;
  .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .book {
      flex: 1;
      margin-bottom: 20px;
      position: relative;
      margin-top: 20px;
      background-color: #fff;
      .imgBar {
        width: 100%;
      }

      .icon {
        position: absolute;
        top: 393px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        color: #d13632;
        font-size: 20px;
        margin-left: 30px;
        // margin-bottom: 20px;
        margin-top: 20px;
      }
      .el-table tr {
        font-size: 16px;
        color: #343434;
        text-align: center;
      }
      .info {
        font-size: 16px;
        color: #343434;
        padding: 20px;
        background-color: #fff;
        .imgClass {
            float: left;
            width: 600px;
            height: 450px;
            margin-right: 30px;
        }
        p {
            width: 100%;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          // letter-spacing: 2px;
          color: #666666;
          // margin: 20px 0;
          text-indent: 2em;
          .top {
            margin: 20px 0;
          }
        }
      }
    }
  }
}
}
// 移动端
@media only screen and (max-width: 768px) {
.branch {
  background-color: #f3f3f3;
  .content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    .book {
      flex: 1;
      margin-bottom: 20px;
      position: relative;
      margin-top: 20px;
      background-color: #fff;
      .imgBar {
        width: 100%;
      }

      .icon {
        position: absolute;
        top: 393px;
        left: 0;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 30px;
      }
      h4 {
        color: #d13632;
        font-size: 20px;
        margin-left: 30px;
        // margin-bottom: 20px;
        // margin-top: 20px;
      }
      .el-table tr {
        font-size: 16px;
        color: #343434;
        text-align: center;
      }
      .info {
        font-size: 16px;
        color: #343434;
        padding: 20px;
        background-color: #fff;
        .imgClass {
            float: left;
            width: 70%;
            height: 40%;
            margin-right: 30px;
        }
        p {
            width: 100%;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 32px;
          // letter-spacing: 2px;
          color: #666666;
          // margin: 20px 0;
          text-indent: 2em;
          .top {
            margin: 20px 0;
          }
        }
      }
    }
  }
}
}
</style>
