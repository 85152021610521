<template>
  <div class="header">
    <div class="main">
      <img
        class="logo"
        src="@/assets/img/logo01.png"
        alt=""  
        @click="$router.push('/index')"
        style="cursor:pointer"
      />
      <ul class="menu_pc" v-if="flag">
      <!-- <ul class="menu_pc" > -->
        <li
          v-for="(item, index) in getMenuList2"
          :key="index"
          class="first_lis"
        >
          <div class="item" @click="toIndex(item.id, index)">
            <i></i> <em>{{ item.value }}</em>
          </div>
          <ul v-if="item.comumns != null && item.comumns != ''">
            <li
              class="submenu"
              v-for="(it, index) in item.comumns"
              :key="index"
              @click="toIndexTwo(it.id, index)"
            >
              <div class="item-lit" :class="{ big: 'stya' }">
                {{ it.value }}
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div v-if="flag2">
        <ul class="menu_pc" v-show="see">
        <!-- <ul class="menu_pc" > -->
          <li
            v-for="(item, index) in getMenuList2"
            :key="index"
            class="first_lis"
            @click="toshow()"
          >
            <div class="item" @click="toIndex(item.id, index)">
              <i></i> <em>{{ item.value }}</em>
            </div>
            <ul v-if="item.comumns != null && item.comumns != ''">
              <li
                class="submenu"
                v-for="(it, index) in item.comumns"
                :key="index"
                @click="toIndexTwo(it.id, index)"
              >
                <div class="item-lit" :class="{ big: 'stya' }">
                  {{ it.value }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="menu_yd ydd clearfix" @click="toshow()">
        <div class="line"></div>
        <div class="line t2"></div>
        <div class="line"></div>
      </div>
      <van-collapse
        v-model="activeNames"
        class="menu_down"
        style="position: absolute ;
          top: 68px ;
          left: 0 ;
          width:100%;
          display:none"
      >
        <van-collapse-item
          v-for="(item, index) in getMenuList2"
          :key="index"
          :title="item.value"
          :name="index + 1"
          @click="toIndex(item.id, index)"
        >
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getMenuList2, getMenuList3, getMenuList4 } from '@/api/myHeader.js'

export default {
  data () {
    return {
      flag: true,
      flag2: false,
      see: false,
      isActive: 0,
      imgUrl: require('@/assets/img/黑色箭头.png'),
      getMenuList2: [],
      getMenuList3: [],
      getMenuList4: [],
      activeNames: [],
      indexro:
        'https://gsxhsdts.tmall.com/shop/view_shop.htm?spm=a220m.1000862.1000730.3.2a5b15eagSxuhg&user_number_id=2207687636872&rn=a4333b59937c8f1422a682a2db22322b',
      indexro1: 'http://124.152.8.8:8010/'
    }
  },
  created () {
    this.getData()
  },

  methods: {
    getData () {
      var windowWidth = document.body.clientWidth
      console.log('视窗宽度', windowWidth)
      if (windowWidth < 768) {
        this.flag = false
        this.flag2 = true
      }
      getMenuList2().then(res => {
        this.getMenuList2 = res.data.data
        console.log('====', this.getMenuList2[7])
        window.console.log(this.getMenuList2)
      })
      getMenuList3().then(res => {
        this.getMenuList3 = res.data.data
      })
      getMenuList4().then(res => {
        this.getMenuList4 = res.data.data
      })
    },
    toIndex (id, index) {
      if (index === 0) {
        this.$router.push({ path: '/index', query: { menuId: id } })
        // 企业风貌
      } else if (index === 1) {
        this.$router.push({ path: '/intro' })
        //企业动态
      } else if (index === 2) {
        this.$router.push({ path: '/dynamic', query: { menuId: id } })
        //党建引领
      } else if (index === 3) {
        // this.$router.push({ path: '/party', query: { menuId: id } })
        this.$router.push({ path: '/party', query: { menuId: id } })
        // 门店风采
      } else if (index === 4) {
        this.$router.push({ path: '/mien', query: { menuId: id } })
      } else if (index === 5) {
        //书香陇原
        this.$router.push({ path: '/longyuan', query: { menuId: id } })
      } else if (index === 6) {
        //时尚阅读
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (index === 7) {
        // 新华传承
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (index === 8) {
        // 网上商城
        window.open(this.indexro, '_blank')
      } else if (index === 9) {
        // 联系我们
        this.$router.push({ path: '/contact', query: { menuId: id } })
      } else if (index === 10) {
        // 网上商城
        window.open(this.indexro1, '_blank')
      }
    },
    toIndexTwo (id, index) {
      this.isActive = index
      console.log('分公司', id)
      if (id === 2133) {
        //人物事迹
        this.$router.push({ path: '/charactersIntro', query: { menuId: id } })
      } else if (id === 2134) {
        //新华历史
        this.$router.push({ path: '/history', query: { menuId: id } })
      } else if (id === 2127) {
        //企业简介
        this.$router.push({ path: '/intro', query: { companyId: id } })
        this.isActive = index
      } else if (id === 2128) {
        //领导班子
        this.$router.push({ path: '/enterprise', query: { companyId: id } })
         this.isActive = index
      } else if (id === 2136) {
        //分公司简介
        this.$router.push({ path: '/filiale', query: { companyId: id } })
         this.isActive = index
      } else if (id === 2129) {
        //组织架构
        this.$router.push({ path: '/framework', query: { companyId: id } })
         this.isActive = index
      } else if (id === 2130) {
        //新书推荐
        this.$router.push({ path: '/recommend', query: { menuId: id } })
      } else if (id === 2135) {
        //党史学习
        this.$router.push({ path: '/study', query: { menuId: id } })
      } else if (id === 2131) {
        //排行榜
        this.$router.push({ path: '/rankingList', query: { menuId: id } })
      } else if (id === 2137) {
        //书籍查询
        this.$router.push({ path: '/bookSearch', query: { menuId: id } })
      } else if (id === 2132) {
        //书目下载
        this.$router.push({ path: '/bookDownload', query: { menuId: id } })
      }
    },
    xs () {},
    toRecommend () {
      this.$router.push('/recommend')
    },
    toMien () {
      this.$router.push('/mien')
    },
    toEnterprise () {
      //领导班子
      this.$router.push('/enterprise')
    },
    toFramework () {
      //组织架构
      this.$router.push('/framework')
    },
    toIntro () {
      //企业简介
      this.$router.push('/intro')
    },
    toParty () {
      this.$router.push('/party')
    },
    toLongyuan () {
      this.$router.push('/longyuan')
    },
    toRankingList () {
      this.$router.push('/rankingList')
    },
    toCharactersIntro () {
      this.$router.push('/charactersIntro')
    },
    toHistory () {
      this.$router.push('/history')
    },
    toStudy () {
      this.$router.push('/study')
    },
    toTianmao () {
      window.open(this.indexro, '_blank')
    },
    toPintai () {
      window.open(this.indexro1, '_blank')
    },
    toshow () {
      this.see = !this.see
    }
  }
}
</script>

<style lang="less">
em {
  font-weight: normal;
  font-style: normal;
}

// pc端
@media only screen and (min-width: 768px) {
  /* 头部 */
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    z-index: 1000;
    .main {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      align-items: center;
      .logo {
        margin-right: 50px;
        width: 187px;
        height: 53px;
      }
      .menu_pc {
        padding: 0;
        cursor: pointer;
        display: flex;
        flex: 1;
        flex-direction: row;
        list-style: none;
        flex: 1;
        li {
          position: relative;
          flex: 1;
          .item {
            display: block;
            height: 80px;
            color: #cc1914;
            text-align: center;

            i {
              margin-top: 15px;
              display: inline-block;
              width: 22px;
              height: 22px;
            }

            em {
              display: block;
              font-size: 16px;
              margin-top: 7px;
            }
          }
          ul {
            display: none;
            position: absolute;
            border-top: 1px solid #fff;
            top: 79px;
            left: 0;
            width: 100%;
            background: #cc1914;
            font-size: 16px;
            li .t1 {
              height: 60px !important;
              line-height: 25px !important;
              padding: 10px 0;
            }
            li .item-lit {
              color: #fff;
              display: inline-block;
              width: 100%;
              padding: 10px 0;
              &:hover {
                background: #fff;
                color: #cc1914;
              }
              .big {
                width: 60px;
              }
            }
          }

          &:hover .item,
          &:hover ul {
            background: #cc1914;
            color: #fff;
            display: block;
            transition: all 0.5s;
            -moz-transition: all 0.5s;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
          }
        }
      }

      li:nth-child(1) {
        .item {
          i {
            background: url('~@/assets/img/a.png') no-repeat;
          }
        }
      }
      li:nth-child(2) {
        .item {
          i {
            background: url('~@/assets/img/b.png') no-repeat;
          }
        }
      }

      li:nth-child(3) {
        .item {
          i {
            background: url('~@/assets/img/z.png') no-repeat;
          }
        }
      }

      li:nth-child(4) {
        .item {
          i {
            background: url('~@/assets/img/c.png') no-repeat;
          }
        }
      }

      li:nth-child(5) {
        .item {
          i {
            background: url('~@/assets/img/d.png') no-repeat;
          }
        }
      }

      li:nth-child(6) {
        .item {
          i {
            background: url('~@/assets/img/e.png') no-repeat;
          }
        }
      }

      li:nth-child(7) {
        .item {
          i {
            background: url('~@/assets/img/f1.png') no-repeat;
          }
        }
      }

      li:nth-child(8) {
        .item {
          i {
            background: url('~@/assets/img/f.png') no-repeat;
          }
        }
      }

      li:nth-child(9) {
        .item {
          i {
            background: url('~@/assets/img/g.png') no-repeat;
          }
        }
      }

      li:nth-child(10) {
        .item {
          i {
            background: url('~@/assets/img/i.png') no-repeat;
          }
        }
      }
      li:nth-child(11) {
        .item {
          i {
            background: url('~@/assets/img/j.png') no-repeat;
          }
        }
      }

      li:nth-child(1):hover {
        .item {
          i {
            background: url('~@/assets/img/a1.png') no-repeat;
          }
        }
      }

      li:nth-child(2):hover {
        .item {
          i {
            background: url('~@/assets/img/a-1.png') no-repeat;
          }
        }
      }

      li:nth-child(3):hover {
        .item {
          i {
            background: url('~@/assets/img/z1.png') no-repeat;
          }
        }
      }

      li:nth-child(4):hover {
        .item {
          i {
            background: url('~@/assets/img/c1.png') no-repeat;
          }
        }
      }

      li:nth-child(5):hover {
        .item {
          i {
            background: url('~@/assets/img/f2.png') no-repeat;
          }
        }
      }

      li:nth-child(6):hover {
        .item {
          i {
            background: url('~@/assets/img/f5.png') no-repeat;
          }
        }
      }

      li:nth-child(7):hover {
        .item {
          i {
            background: url('~@/assets/img/f4.png') no-repeat;
          }
        }
      }

      li:nth-child(8):hover {
        .item {
          i {
            background: url('~@/assets/img/f6.png') no-repeat;
          }
        }
      }

      li:nth-child(9):hover {
        .item {
          i {
            background: url('~@/assets/img/g1.png') no-repeat;
          }
        }
      }

      li:nth-child(10):hover {
        .item {
          i {
            background: url('~@/assets/img/i1.png') no-repeat;
          }
        }
      }
      li:nth-child(11):hover {
        .item {
          i {
            background: url('~@/assets/img/j1.png') no-repeat;
          }
        }
      }
    }
  }
}
// 移动端样式
@media only screen and (max-width: 768px) {
  /* 头部 */
  .header {
    position: fixed;
    width: 100%;
    z-index: 120;
    background: rgba(255, 255, 255, 0.7);

    .main {
      .logo {
        width: 150px;
        height: 45px;
        margin-left: 10px;
        margin-top: 15px;
      }
      .first_lis {
        display: flex;
        align-items: center;
        height: 6%;
        border-bottom: 1px solid #a9a9a9;
      }
      .menu_pc {
        margin-top: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #a9a9a9;
        width: 750px;
        height: 800px;
        z-index: 100;
        //  background:pink;
        background: #ffffff;
      }
      .item {
        width: 100%;
      }
      .item em {
        margin-left: 10px;
        font-weight: 600;
        font-size: 14px;
      }
      .submenu {
        display: none;
      }
      .menu_yd {
        position: absolute;
        top: 25px;
        right: 30px;
        width: 25px;
        height: 20px;
        .line {
          width: 100%;
          height: 3px;
          background-color: #cc1914;
        }
        .t2 {
          margin: 6px 0;
        }
        .van-hairline--top-bottom {
          width: 100%;
        }
        .van-collapse-item__wrapper {
          width: 100%;
        }
        .van-hairline--top-bottom {
          position: absolute;
          top: 68px;
          left: 0;
        }
        li .item-lit {
          color: #fff;
          display: inline-block;
          width: 100%;
          padding: 10px 0;
          &:hover {
            background: #fff;
            color: #cc1914;
          }
          .big {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>
