<template>
  <div>
    <d-player
      :options="{
        autoplay: true,
        volume: 0.7,
        video: { url }
      }"
    ></d-player>
  </div>
</template>

<script>
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'

export default {
  name: 'Player',
  props: {
    url: {
      type: String
    },
    pic: {
      type: String
    }
  },
  components: {
    'd-player': VueDPlayer
  }
}
</script>

<style scoped>
.dplayer {
  height: 365px;
}
</style>
