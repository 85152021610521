import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/base.css'
Vue.use(ElementUI)
import 'lib-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
// axios使用
import axios from 'axios'
const _axios = axios.create({
  baseURL: process.env.VUE_APP_URL,
  withCredentials: true
})
Vue.prototype.$axios = _axios
Vue.config.productionTip = false

import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

Vue.use(VueAwesomeSwiper /* { default options with global component } */)

Vue.filter('replaceHtmlTag', (content) => {
  return content.replace(/<[^<>]+>/g, '')
})
Vue.prototype.$replaceHtml = (content) => {
  return content.replace(/<[^<>]+>/g, '')
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
